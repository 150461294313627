import { catchError, from, switchMap, tap, throwError } from 'rxjs';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, AppConfig } from '@pm/config';
import { PmAuthService } from '../services/pm-auth-service.service';
import { Router } from '@angular/router';
import { JwtToken } from '../models';
import { AUTH_ENDPOINTS } from '../models/pm-auth.constants';
import { APP_TYPE, AppType } from '@pm/core/utils';

@Injectable()
export class PmAuthHttpInterceptorService implements HttpInterceptor {
  ignoreUrl = ['jwt/token', 'jwt/refresh', 'public/profit/version/latest'];

  constructor(
    private authService: PmAuthService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly _router: Router,
    private readonly _http: HttpClient,
    @Inject(APP_TYPE) private _appType: AppType,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (!req.url.includes(this.appConfig.api)) {
      return next.handle(req);
    }

    const token$ = from(this.authService.getToken());

    return token$.pipe(
      switchMap((authToken) => {
        if (!authToken) {
          return next.handle(req);
        }
        if (this.ignoreUrl.some((url) => req.url.includes(url))) {
          return next.handle(req);
        }
        const authReq = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + authToken,
          },
        });
        return next.handle(authReq).pipe(
          catchError((error) => {
            if (
              error instanceof HttpErrorResponse &&
              !AUTH_ENDPOINTS.some((url) => req.url.includes(url)) &&
              [401, 403].includes(error.status)
            ) {
              return this._refreshToken$().pipe(
                tap((token) => this.authService.setToken(token.token)),
                switchMap((token) => {
                  return next.handle(
                    req.clone({
                      setHeaders: {
                        Authorization: 'Bearer ' + token.token,
                      },
                    }),
                  );
                }),
                catchError((error) => {
                  return this._logout(error);
                }),
              );
            }

            return throwError(() => error);
          }),
        );
      }),
    );
  }

  private _refreshToken$() {
    const refreshToken$ = from(this.authService.getRefreshToken());

    return refreshToken$.pipe(
      switchMap((refreshToken) =>
        this._http.get<JwtToken>(this.appConfig.api + '/jwt/refresh', {
          params: {
            _format: 'json',
          },
          headers: {
            Authorization: 'Bearer ' + refreshToken,
          },
        }),
      ),
    );
  }

  private _logout(error: unknown) {
    return from(this.authService.signOut()).pipe(
      switchMap(() => {
        if (this._appType === AppType.dashboard) {
          this._router.navigate(['/auth/signin']);
        }
        return throwError(() => error);
      }),
    );
  }
}

export const authInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: PmAuthHttpInterceptorService,
    multi: true,
  },
];
