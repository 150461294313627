import { InjectionToken, Injector } from '@angular/core';

export const APP_CONFIG = new InjectionToken('Profitmark config');
export interface AppConfig {
  appType: string;
  api: string;
  mainCountry: string;
  recaptchaKey?: string;
  parkovka?: string;
  dashboard?: string;
  domain?: string;
  currency?: string;
  seller?: string;
  isEuPageEnabled?: boolean;
  pageTitle?: string;
  gtm?: string;
  shark?: string;
  howToVideo?: string;
  frontPageVideo?: string;
  contactEmail?: string;
  localeCode?: string;
  mainDomainZone?: string;
  baseUrl?: string;
  isEuGrantEnabled?: boolean;
  contactNumber?: string;
  pageDescription?: string;
  metaDescriptionTemplate?: string;
  metaTitleTemplate?: string;
  stripeKey?: string;
  isBlogEnabled?: boolean;
  isEuGrantToggable?: boolean;
  firebase?: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
    vapidKey: string;
  };
  appStoreLink?: string;
  playMarketLink?: string;
  origin?: string;
  defaultSearchField?: string;
  trustPilotUrl?: string;
  siteName?: string;
  isSimpleFormEnabled?: boolean;
  isSearchEnabled?: boolean;
  hjid?: number;
  formSteps?: string[];
  whatsappNumber?: string;
  routesFor?: string;
  customSitemap?: boolean;
  enabledLanguages?: string[];
  reviewsProvider?: string;
  googleReviewsUrl?: string;
  socialLinks?: {
    facebook?: string;
    instagram?: string;
    linkedin?: string;
  };
  messangersLinks?: {
    facebook?: string;
    whatsapp?: string;
    telegram?: string;
    viber?: string;
  };
}

export const getConfigValueFactory = (key: string) => (injector: Injector) => {
  const config = injector.get(APP_CONFIG) as AppConfig;
  return config[key as keyof AppConfig];
};
